/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

import { Link } from "gatsby";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import { navigate } from "gatsby";

import Modal from '@material-ui/core/Modal';

// import Header from "./header"
import "./layout.css"

const goToLink = (link) => {
  
  navigate(link);
}

const Layout = ({ children }) => {

  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  const [open, setOpen] = React.useState(false);
  
  const toggleMenu = () => {

    setOpen(open => !open);

  }

  const ShowMobileMenu = () => {

    return (

        <Grid container className="mobileMenu">

            <Grid className="mobileMenu_item" item><Link className="navLink" name="Home" to="/">Home</Link></Grid>
            <Grid className="mobileMenu_item" item><Link className="navLink" name="Work" to="/work/">Work</Link></Grid>
            <Grid className="mobileMenu_item" item><Link className="navLink" name="Hire Me" to="/hireme/">Hire Me</Link></Grid>
            <Grid className="mobileMenu_item" item><Link className="navLink" name="Contact" to="/contact/">Contact</Link></Grid>
            <Grid className="mobileMenu_item" item><Link className="navLink" name="Blog" to="/blog/">Blog</Link></Grid>
   
        </Grid>

    )

  }

  return (
    <>
      
      <div>

        {/* desktop nav -------------------------------------------- */}
        <Hidden only={['xs']}>

          <Grid container className="navigation_desktop">

            <Grid onClick={() => goToLink('/')} item className="logo"></Grid>

            <Grid item className="navigation_desktop_items">

                  <Grid item><Link activeClassName="active" className="navLink" name="Home" to="/">Home</Link></Grid>
                  <Grid item><Link activeClassName="active" className="navLink" name="Work" to="/work/">Work</Link></Grid>
                  <Grid item><Link activeClassName="active" className="navLink" name="Hire Me" to="/hireme/">Hire Me</Link></Grid>
                  <Grid item><Link activeClassName="active" className="navLink" name="Contact" to="/contact/">Contact</Link></Grid>
                  <Grid item><Link activeClassName="active" className="navLink" name="Blog" to="/blog/">Blog</Link></Grid>

            </Grid>

          </Grid>

        </Hidden>

        {/* mobile nav -------------------------------------------- */}
        <Hidden only={['sm', 'md', 'lg', 'xl']}>
        <React.Fragment>  

          <Grid item className="navigation_mobile_icon">{!open ? <MenuIcon onClick={toggleMenu} /> : <CloseIcon onClick={toggleMenu} />}</Grid>

            <Modal
              open={open}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
            
            <ShowMobileMenu />
            </Modal>

        </React.Fragment> 

        </Hidden>

        <main>{!open && children}</main>
        <footer>

        {!open &&
        <React.Fragment>  
         <Grid container className="footer">

            <Grid item className="footer_logo"></Grid>
            <Grid item className="footer_phone_number">Call <a href="tel:07717 797 259">07717 797 259</a></Grid>

         </Grid>

        {/* <Grid container className="orangeFooter">
         ©2018 by The Sharpener
         </Grid>*/}
         </React.Fragment> 
       }
        </footer>
      </div>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
